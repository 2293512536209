<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>

    <div v-else-if="!updating && currentProfile">
      <UiBanner>
        <template v-slot:content>
          <div class="tw-flex">
            <v-btn small icon class="tw--ml-2 tw-mr-2" :to="{ name: 'ProfileHubContactsList' }" exact
              ><v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            </v-btn>
            <UiTitle :title="`${currentProfile.gtr.prenom} ${currentProfile.gtr.nom}`" large></UiTitle>
          </div>
        </template>
      </UiBanner>

      <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-6 tw-p-6">
        <v-card :width="380" class="tw-p-6">
          <div class="tw-flex tw-flex-col tw-items-center">
            <v-avatar color="primary" size="48">
              <v-icon v-if="currentProfile.gtr.genre === 'M'" dark>{{ icons.mdiHumanMale }}</v-icon>
              <v-icon v-else-if="currentProfile.gtr.genre === 'F'" dark>{{ icons.mdiHumanFemale }}</v-icon>
              <span v-else class="white--text text-h6">{{ computedInitials }}</span>
            </v-avatar>
            <span class="tw-ml-2 tw-text-xl tw-font-semibold">{{
              `${currentProfile.gtr.prenom} ${currentProfile.gtr.nom}`
            }}</span>
            <span class="tw-text-sm">{{
              currentProfile.gtr.personne != null ? currentProfile.gtr.personne : '-'
            }}</span>
          </div>
          <div class="tw-flex tw-items-center tw-mt-6">
            <v-icon>{{ icons.mdiWeb }}</v-icon>
            <div class="tw-flex tw-flex-col tw-ml-2">
              <span class="tw-text-xs tw-text-gray-500">{{ $t('profilehub.individual.identity.language') }}</span>
              <span class="tw-text-sm">{{ currentProfile.gtr.langue != null ? currentProfile.gtr.langue : '-' }}</span>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-mt-6">
            <v-icon>{{ icons.mdiGenderMaleFemale }}</v-icon>
            <div class="tw-flex tw-flex-col tw-ml-2">
              <span class="tw-text-xs tw-text-gray-500">{{ $t('profilehub.individual.identity.gender') }}</span>
              <span class="tw-text-sm">{{ currentProfile.gtr.genre != null ? currentProfile.gtr.genre : '-' }}</span>
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-mt-6">
            <v-icon>{{ icons.mdiSourceFork }}</v-icon>
            <div class="tw-flex tw-flex-col tw-ml-2">
              <span class="tw-text-xs tw-text-gray-500">{{ $t('profilehub.individual.identity.source') }}</span>
              <span class="tw-text-sm">{{ currentProfile.gtr.source != null ? currentProfile.gtr.source : '-' }}</span>
            </div>
          </div>
          <div class="tw-flex tw-col-span-2 tw-mt-6">
            <v-icon>{{ icons.mdiEmailOutline }}</v-icon>
            <div class="tw-flex tw-flex-col tw-ml-2">
              <span class="tw-text-xs tw-text-gray-500">{{ $t('profilehub.individual.contact.email') }}</span>
              <span class="tw-text-sm">{{ currentProfile.gtr.email != null ? currentProfile.gtr.email : '-' }}</span>
              <div>
                <div class="tw-flex tw-items-center">
                  <v-btn
                    class="tw-h-6 tw-w-6 tw-mr-2"
                    v-if="currentProfile.gtr.optin === '0'"
                    color="error"
                    icon
                    @click="openConfirmOptIn()"
                    :loading="opting"
                    :disable="opting"
                  >
                    <v-icon>{{ icons.mdiToggleSwitchOff }}</v-icon>
                  </v-btn>
                  <v-btn
                    class="tw-h-6 tw-w-6 tw-mr-2"
                    v-else-if="currentProfile.gtr.optin === '1'"
                    color="success"
                    icon
                    @click="toggleOptIn(computedProfileId, '0')"
                    :loading="opting"
                    :disable="opting"
                  >
                    <v-icon>{{ icons.mdiToggleSwitch }}</v-icon>
                  </v-btn>
                  <span class="tw-text-xs">{{ $t('profilehub.individual.contact.optinEmail.label') }}</span>
                </div>
              </div>
              <div class="tw-flex tw-flex-col">
                <span class="tw-text-xs">{{
                  `${$t('profilehub.individual.contact.optinEmail.since')}${
                    currentProfile.gtr.optindate != null ? currentProfile.gtr.optindate : '-'
                  }`
                }}</span>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-mt-6">
            <v-icon>{{ icons.mdiPhoneOutline }}</v-icon>
            <div class="tw-flex tw-flex-col tw-ml-2">
              <span class="tw-text-xs tw-text-gray-500">{{ $t('profilehub.individual.contact.phone') }}</span>
              <span class="tw-text-xs">{{
                currentProfile.gtr.telephone != null ? currentProfile.gtr.telephone : '-'
              }}</span>
              <span class="tw-text-xs"> * {{ $t('profilehub.individual.contact.disclaimer') }} </span>
            </div>
          </div>
        </v-card>
        <div class="tw-flex tw-flex-col tw-flex-grow tw-p-6">
          <section class="">
            <UiTitle class="tw-mb-3 tw-text-base" :title="$t('profilehub.individual.gtrCriteria.title')" />
            <div class="tw-grid tw-grid-cols-2 tw-gap-6">
              <div>
                <UiSubtitle :subtitle="$t('profilehub.individual.gtrCriteria.dateOfBirth')" />
                <div>
                  {{ currentProfile.gtr.datedenaissance != null ? currentProfile.gtr.datedenaissance : '-' }}
                </div>
              </div>
              <div>
                <UiSubtitle :subtitle="$t('profilehub.individual.gtrCriteria.country')" />
                <div>
                  {{ currentProfile.gtr.pays != null ? currentProfile.gtr.pays : '-' }}
                </div>
              </div>
              <div>
                <UiSubtitle :subtitle="$t('profilehub.individual.gtrCriteria.postalCode')" />
                <div>
                  {{ currentProfile.gtr.codepostal != null ? currentProfile.gtr.codepostal : '-' }}
                </div>
              </div>
            </div>
          </section>
          <section class="tw-mt-6">
            <UiTitle class="tw-mb-3 tw-text-base" :title="$t('profilehub.individual.customCriteria.title')" />
            <div class="tw-grid tw-grid-cols-2 tw-gap-6">
              <div v-for="(value, name) in currentProfile.custom" :key="name" class="tw-flex">
                <div class="tw-flex tw-flex-col">
                  <UiSubtitle class="tw-capitalize" :subtitle="name" />
                  <div>{{ value }}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ProfileHubContactsOptinConfirm
        ref="optinConfirm"
        :dialog="dialogConsent"
        :item="currentProfile.gtr"
        :loading="opting"
        @close="closeModal"
        @confirm="toggleOptIn($event, '1')"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import {
  mdiArrowLeft,
  mdiHumanMale,
  mdiHumanFemale,
  mdiWeb,
  mdiGenderMaleFemale,
  mdiSourceFork,
  mdiEmailOutline,
  mdiToggleSwitch,
  mdiToggleSwitchOff,
  mdiPhoneOutline,
} from '@mdi/js'
import UiBanner from '@/components/UI/Banner.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import ProfileHubContactsOptinConfirm from '@/components/ProfileHub/Contacts/OptinConfirm.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProfileHubContactsIndividual',
  components: {
    UiTitle,
    UiSubtitle,
    UiBanner,
    ProfileHubContactsOptinConfirm,
  },
  data: () => ({
    opting: false,
    dialogConsent: false,
    icons: {
      mdiArrowLeft,
      mdiHumanMale,
      mdiHumanFemale,
      mdiWeb,
      mdiGenderMaleFemale,
      mdiSourceFork,
      mdiEmailOutline,
      mdiToggleSwitch,
      mdiToggleSwitchOff,
      mdiPhoneOutline,
    },
  }),
  async created() {
    this.setUpdating(true)
    try {
      await this.setCurrentProfile(this.$route.params.id)
      this.setUpdating(false)
    } catch {
      this.setAlert({
        color: 'error',
        text: this.$t('error.notification.default'),
      })
    }
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentProfile: state => state.profilehub.currentProfile,
    }),
    computedInitials() {
      return this.currentProfile.gtr.prenom
        ? `${this.currentProfile.gtr.prenom.charAt(0).toUpperCase()}${this.currentProfile.gtr.nom
            .charAt(0)
            .toUpperCase()}`
        : ''
    },
    computedCustomCriteria() {
      const customCriteriaArray = []
      for (const criteria in this.currentProfile.custom) {
        customCriteriaArray.push({ [criteria]: this.currentProfile.custom[criteria] })
      }
      return customCriteriaArray
    },
    computedProfileId() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      setCurrentProfile: 'profilehub/setCurrentProfile',
      updateOptin: 'profilehub/updateOptin',
      setAlert: 'backoffice/setAlert',
    }),

    closeModal() {
      this.dialogConsent = false
    },

    openConfirmOptIn() {
      this.dialogConsent = true
    },
    async toggleOptIn(_, newValue) {
      this.opting = true
      const item = {
        id: this.currentProfile.id,
      }
      try {
        await this.updateOptin({ profile: item, optinValue: newValue })
        this.setAlert({
          color: 'success',
          text: this.$t('profilehub.contacts.notification.optIn'),
        })
        await this.setCurrentProfile(this.currentProfile.id)
        this.closeModal()
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.opting = false
    },
  },
}
</script>
