<template>
  <UiDialog :dialog="dialog" :max-width="400" scrollable persistent>
    <template v-slot:header><UiTitle :title="$t('profilehub.contacts.modal.optInConfirm.title')" /></template>
    <template v-slot:body>
      <div class="tw-px-4 tw-py-6 sm:tw-px-6">
        <v-checkbox v-model="confirm" class="tw-p-0 tw-m-0" hide-details>
          <template v-slot:label>
            <div v-dompurify-html="$t('profilehub.contacts.modal.optInConfirm.label', { name: item.email })">
              <span class="tw-font-medium">{{ item.firstName }} {{ item.lastName }}</span>
            </div>
          </template>
        </v-checkbox>
      </div></template
    >
    <template v-slot:actions>
      <v-btn type="submit" rounded plain @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-spacer />

      <v-btn
        type="submit"
        rounded
        color="primary"
        @click="confirmConsent"
        :loading="loading"
        :disabled="!confirm || loading"
      >
        {{ $t('button.confirm') }}
      </v-btn>
    </template>
  </UiDialog>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiDialog from '@/components/UI/Dialog.vue'

let initialData = () => ({
  confirm: false,
})

export default {
  name: 'ProfileHubContactsOptinConfirm',
  components: {
    UiTitle,
    UiDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => initialData(),
  methods: {
    confirmConsent() {
      this.$emit('confirm', this.item)
    },
    resetData() {
      Object.assign(this.$data, initialData())
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-hub-contacts-optin-confirm {
  &__card {
    @apply tw-rounded-2xl;

    &__header,
    &__actions {
      @apply tw-p-4 sm:tw-px-6;
    }

    &__body {
      @apply tw-h-full tw-overflow-y-auto tw-p-4 sm:tw-p-6;
    }

    &__actions {
      @apply tw-flex tw-justify-between;
    }
  }
}
</style>
