<template>
  <v-dialog
    :value="dialog"
    :max-width="maxWidth"
    class="ui-dialog"
    :scrollable="scrollable"
    :persistent="persistent"
    @click:outside="$emit('close')"
    @update:return-value="$emit('close')"
  >
    <v-card class="ui-dialog__card">
      <div class="ui-dialog__card__header" v-if="!noHeader">
        <slot name="header"></slot>
      </div>
      <v-divider />
      <div class="ui-dialog__card__body">
        <slot name="body"></slot>
      </div>
      <v-divider />
      <div class="ui-dialog__card__actions">
        <slot name="actions"></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UiDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: true,
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: 700,
    },
    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-dialog {
  &__card {
    @apply tw-rounded-2xl;

    &__header,
    &__actions {
      @apply tw-p-4 sm:tw-px-6;
    }

    &__body {
      @apply tw-h-full tw-overflow-y-auto;
    }

    &__actions {
      @apply tw-flex tw-justify-center;
    }
  }
}
</style>
